<script>
import ThemeButtonVue from '@/components/ThemeButton.vue';

export default {
  name: 'HeaderVue',

  components: { ThemeButtonVue },

  props: {
    pageTheme: Object,
    switchTheme: Function,
  },
};
</script>

<template>
  <header class="header">
    <router-link class="header__logo" to="/"> </router-link>
    <ThemeButtonVue :theme="pageTheme" :switchTheme="switchTheme" />
  </header>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.header__logo {
  max-width: 200px;
  width: 100%;
  background-image: url('@/assets/pg-logo-dark.png');
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.3s linear;
  padding: 15px;
}

.sun .header__logo {
  background-image: url('@/assets/pg-logo.png');
}
</style>
