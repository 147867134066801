import { createStore } from 'vuex';

export default createStore({
  state: {
    cardData: {},
    error: '',
  },
  getters: {},

  mutations: {
    setCardData(state, data) {
      state.cardData = data;
    },

    setError(state, key) {
      state.error = key;
    },
  },

  actions: {},

  modules: {},
});
