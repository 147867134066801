import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Mobipay • Категории',
    },
    component: () => import('../views/HomePage.vue'),
  },
  {
    path: '/category/:category',
    name: 'category',
    component: () => import('../views/CategoryPage.vue'),
    meta: {
      title: 'Mobipay • Сервисы',
    },
  },

  {
    path: '/payment/:id',
    name: 'payment',
    component: () => import('../views/PaymentPage.vue'),
    meta: {
      title: 'Mobipay • Платёжная форма',
    },
  },

  {
    path: '/status-page/:status',
    name: 'status',
    component: () => import('../views/StatusPage.vue'),
    meta: {
      title: 'Mobipay • Статус транзакции',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const title = `${to.meta.title}`;

  if (title) {
    document.title = title;
  }
  // Continue resolving the route
  next();
});

export default router;
