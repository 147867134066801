export const validation = {
  mounted() {
    const validateForm = document.querySelector('.payment-form');
    const submitButton = validateForm.querySelector('.payment-form__submit');
    const inputList = Array.from(validateForm.querySelectorAll('.payment-form__input'));
    let inputElement;
    function enableValidation() {
      validateForm.addEventListener('submit', (evt) => evt.preventDefault());
      setEventListeners();
    }

    function setEventListeners() {
      deactiveButton();
      inputList.forEach((input) => {
        input.addEventListener('keyup', () => {
          checkInputValidity(input);
          deactiveButton();
        });
      });
    }

    function checkInputValidity(input) {
      const allowedOperators = ['701', '702', '775', '778'];
      inputElement = input;
      if (input.type === 'tel') {
        if (
          !allowedOperators.includes(inputElement.value.slice(4, 7)) &&
          inputElement.value.length >= 7
        ) {
          showError('Неверный номер телефона');
        } else if (input.value.length < 18) {
          showError('Неверный номер телефона');
        } else if (input.value.length === 0) {
          showError('Вы пропустили это поле');
        } else {
          hideError();
        }
      } else if (input.id === 'amount') {
        if (input.value.length === 0) {
          showError('Вы пропустили это поле');
        } else if (input.value > 150000) {
          showError('Максимальная сумма оплаты 150 000kzt');
        } else if (input.value < 10) {
          showError('Минимальная сумма оплаты 10kzt');
        } else {
          hideError();
        }
      } else {
        !input.validity.valid ? showError() : hideError();
      }
    }

    function showError(validationMessage) {
      inputElement.classList.add('payment-form__error');
      let errorElement = inputElement.closest('label').querySelector('.payment-form__input-error');
      errorElement.textContent = validationMessage
        ? validationMessage
        : inputElement.validationMessage;
      errorElement.classList.add('payment-form__input-error_active');
    }

    function hideError() {
      inputElement.classList.remove('payment-form__error');
      let errorElement = inputElement.closest('label').querySelector('.payment-form__input-error');
      errorElement.textContent = '';
      errorElement.classList.remove('payment-form__input-error_active');
    }

    function deactiveButton() {
      if (hasInvalidInput()) {
        submitButton.setAttribute('disabled', 'true');
      } else {
        submitButton.removeAttribute('disabled');
      }
    }

    function hasInvalidInput() {
      const allowedOperators = ['701', '702', '775', '778'];
      return inputList.some((input) => {
        if (input.type === 'tel') {
          if (!allowedOperators.includes(input.value.slice(4, 7)) && input.value.length >= 7) {
            return true;
          } else if (input.value.length < 18) {
            return true;
          } else if (input.value.length === 0) {
            return true;
          } else {
            return false;
          }
        } else if (input.id === 'amount') {
          if (input.value.length === 0) {
            return true;
          } else if (input.value > 150000) {
            return true;
          } else if (input.value < 10) {
            return true;
          } else {
            return false;
          }
        } else {
          return !input.validity.valid;
        }
      });
    }

    enableValidation();
  },
};
