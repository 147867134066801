<script>
export default {
  name: 'ThemeButtonVue',

  props: {
    theme: Object,
    switchTheme: Function,
  },

  data: () => {
    return {};
  },
};
</script>

<template>
  <input @change="switchTheme" :checked="theme.dark" type="checkbox" id="toggle_checkbox" />

  <label for="toggle_checkbox">
    <div id="star">
      <div class="star" id="star-1">★</div>
      <div class="star" id="star-2">★</div>
    </div>
    <div id="moon"></div>
  </label>
</template>

<style scoped>
* {
  user-select: none;
}

#toggle_checkbox {
  display: none;
}

label {
  display: block;
  position: relative;
  width: 50px;
  height: 20px;

  background-color: #006eef;
  border-radius: 56px;
  cursor: pointer;
  transition: 0.3s ease background-color;
  overflow: hidden;
  z-index: 1000;
}

#star {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 12px;
  height: 12px;
  background-color: #fafd0f;
  transform: scale(1);
  border-radius: 50%;
  transition: 0.3s ease top, 0.3s ease left, 0.3s ease transform, 0.3s ease background-color;
  z-index: 1;
}

#star-1 {
  position: relative;
}

#star-2 {
  position: absolute;
  transform: rotateZ(36deg);
}

.star {
  top: -2.7px;
  left: -3.7px;
  font-size: 19px;
  line-height: 17px;
  color: #fafd0f;
  transition: 0.3s ease color;
}

#moon {
  position: absolute;
  bottom: -52px;
  right: 5px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s ease bottom;
}

#moon:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -9px;
  width: 18px;
  height: 18px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s ease background-color;
}

#toggle_checkbox:checked + label {
  background-color: #242630;
}

#toggle_checkbox:checked + label #star {
  top: 5px;
  left: 60px;
  transform: scale(0.4);
  opacity: 0.5;
  background-color: yellow;
}

#toggle_checkbox:checked + label .star {
  color: yellow;
}

#toggle_checkbox:checked + label #moon {
  bottom: 3px;
}

#toggle_checkbox:checked + label #moon:before {
  background-color: #242630;
}
</style>
