<script>
export default {
  name: 'BackpageButtonVue',

  data: () => {
    return {};
  },
};
</script>

<template>
  <button class="back-page__button" type="button" @click="$router.go(-1)">
    <svg
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
    </svg>
  </button>
</template>

<style scoped>
.back-page__button {
  color: #fff;
  background: #45464f;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: 0.3s linear;
}

.back-page__button svg {
  display: block;
  width: 20px;
  height: 20px;
  margin: auto;
}

.back-page__button:hover {
  background: #03a9f4;
}

.sun .back-page__button {
  background: #ecedf1;
  color: #060b27;
}

.sun .back-page__button:hover {
  background: #060b27;
  color: #fff;
}

@media screen and (max-width: 950px) {
  .back-page__button {
    position: absolute;
    left: 10px;
  }
}
</style>
