<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BackpageButtonVue from '@/components/BackPageButton.vue';

export default {
  name: 'App',
  components: { BackpageButtonVue, Footer, Header },

  mounted() {
    localStorage.darkmode ? (this.pageTheme.dark = true) : '';
  },

  data: () => {
    return {
      pageTheme: {
        dark: false,
      },
    };
  },

  methods: {
    pageSwitchTheme() {
      this.pageTheme.dark = !this.pageTheme.dark;
      localStorage.darkmode
        ? localStorage.removeItem('darkmode')
        : localStorage.setItem('darkmode', 'true');
    },
  },
};
</script>

<template>
  <div class="page" :class="{ sun: !pageTheme.dark }">
    <Header :pageTheme="pageTheme" :switchTheme="pageSwitchTheme" />
    <main class="main">
      <BackpageButtonVue
        v-if="
          $route.path !== '/' &&
          $route.path !== '/status-page/ok' &&
          $route.path !== '/status-page/error'
        " />
      <router-view :key="$route.path" />
    </main>
    <Footer />
  </div>
</template>

<style>
.page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #000;
  transition: background-color 0.3s linear;
}

.sun.page {
  color: #0f0c27;
  background-color: #fff;
}

.main {
  display: flex;
  justify-content: center;
  padding: 20px 0 30px 0;
}
</style>
