<script>
export default {
  name: 'FooterVue',
};
</script>

<template>
  <footer class="footer">
    <div class="footer__copyright">
      <a target="_blank" href="http://paygate.kz/">
        <svg
          class="footer__logo"
          viewBox="0 0 98 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-v-114330cc="">
          <path
            d="M22.822 8.54126V11.8209C22.8193 12.8325 22.4208 13.8019 21.7134 14.5173C21.006 15.2326 20.0474 15.6356 19.0471 15.6383H9.67822C8.67764 15.6364 7.71857 15.2336 7.01104 14.5181C6.30352 13.8026 5.9052 12.8328 5.90332 11.8209V8.10301C5.9052 7.09117 6.30352 6.12131 7.01104 5.40583C7.71857 4.69035 8.67764 4.28755 9.67822 4.28564H18.3043V6.80413H9.67822C9.33876 6.80677 9.01393 6.94431 8.77388 7.18706C8.53383 7.4298 8.39782 7.75828 8.39521 8.10157V11.8209C8.39782 12.1642 8.53383 12.4927 8.77388 12.7354C9.01393 12.9782 9.33876 13.1157 9.67822 13.1184H19.0471C19.3865 13.1157 19.7113 12.9782 19.9514 12.7354C20.1914 12.4927 20.3275 12.1642 20.3301 11.8209V10.8118H10.3939V8.55712C10.7788 8.55712 11.1589 8.55183 11.5343 8.54126H22.822Z"
            fill="#0F0C27"
            data-v-114330cc=""></path>
          <path
            d="M1.94733 15.9942V3.8116C1.95072 2.80076 2.3497 1.83238 3.05706 1.11814C3.76442 0.403904 4.72263 0.00189929 5.72223 0L19.047 0C20.0474 0.00266365 21.006 0.405705 21.7134 1.12102C22.4207 1.83634 22.8193 2.80576 22.8219 3.81737V6.81158H20.3315V3.81737C20.3288 3.47408 20.1928 3.1456 19.9528 2.90285C19.7127 2.6601 19.3879 2.52256 19.0484 2.51992H5.72223C5.38276 2.52256 5.05794 2.6601 4.81789 2.90285C4.57784 3.1456 4.44183 3.47408 4.43922 3.81737V16L1.94733 15.9942Z"
            fill="#0F0C27"
            data-v-114330cc=""></path>
          <path
            d="M0.935173 1.39689V0.373349H1.14045L1.37995 1.09415C1.40276 1.16191 1.41844 1.21236 1.42842 1.23831C1.43982 1.20083 1.45836 1.14605 1.48259 1.07397L1.72494 0.361816H1.90456V1.38535H1.77626V0.540575L1.47831 1.39689H1.35714L1.06347 0.531926V1.39689H0.935173ZM0.333583 1.39689V0.494444H0V0.373349H0.802595V0.494444H0.467586V1.39689H0.333583Z"
            fill="#0F0C27"
            data-v-114330cc=""></path>
          <path
            d="M50.2994 9.07468L46.2696 2.82088H48.9679L51.471 6.96483L54.0155 2.82088H56.6369L52.6072 9.03388V13.1487H50.2994V9.07468ZM43.1984 8.82406L41.8078 5.47277L40.4113 8.82406H43.1984ZM40.7723 2.74512H42.9026L47.3939 13.1487H44.9677L44.0091 10.8173H39.571L38.6124 13.1487H36.2454L40.7723 2.74512ZM32.334 8.02558C32.8265 8.05175 33.3101 7.88879 33.6832 7.57097C33.846 7.42401 33.9736 7.24311 34.0565 7.04146C34.1394 6.83981 34.1756 6.62248 34.1625 6.40531C34.1625 5.36204 33.5352 4.83748 32.2866 4.83748H30.5114V7.99644L32.334 8.02558ZM28.1918 2.82088H32.476C33.5574 2.7612 34.6195 3.12106 35.4347 3.82336C35.7906 4.15527 36.0698 4.55862 36.2536 5.0061C36.4374 5.45358 36.5213 5.93473 36.4999 6.41696C36.5236 6.93395 36.4234 7.44917 36.2075 7.92099C35.9917 8.39281 35.6661 8.80798 35.2572 9.13296C34.3984 9.77936 33.337 10.1093 32.2571 10.0655H30.4818V13.1662H28.1977L28.1918 2.82088Z"
            fill="#0F0C27"
            data-v-114330cc=""></path>
          <path
            d="M90.0291 2.82097H97.9408V4.82009H92.3192V6.91829H97.2544V8.94072H92.3074V11.1089H98V13.1313H90.0291V2.82097ZM82.7566 4.91334H79.5671V2.82097H88.2598V4.91334H85.0644V13.1488H82.7566V4.91334ZM75.8509 8.82415L74.4603 5.47286L73.0638 8.82415H75.8509ZM73.4248 2.74521H75.5492L80.0464 13.1488H77.6321L76.6735 10.8174H72.2413L71.2827 13.1488H68.9157L73.4248 2.74521ZM63.1284 13.3295C62.3939 13.3574 61.6614 13.2374 60.9756 12.9768C60.2898 12.7163 59.6652 12.3206 59.1401 11.8141C58.1571 10.7903 57.6094 9.43473 57.6094 8.02567C57.6094 6.61661 58.1571 5.26102 59.1401 4.23726C59.6525 3.72344 60.2669 3.31907 60.9449 3.04933C61.6229 2.7796 62.3501 2.65028 63.0811 2.66943C64.5915 2.62517 66.0657 3.12922 67.2233 4.08572L65.7676 5.83422C65.013 5.17008 64.0398 4.79742 63.0278 4.78512C62.6233 4.78078 62.2228 4.86416 61.8546 5.02934C61.4865 5.19453 61.1597 5.43748 60.8976 5.74097C60.3153 6.38211 59.9934 7.21212 59.9934 8.0723C59.9934 8.93248 60.3153 9.76249 60.8976 10.4036C61.1873 10.7103 61.5409 10.9519 61.934 11.1118C62.3272 11.2717 62.7507 11.3462 63.1758 11.3303C63.9483 11.3595 64.7098 11.1429 65.3475 10.7125V9.23214H63.0515V7.27382H67.6079V11.7558C66.3596 12.8009 64.7665 13.3606 63.1284 13.3295Z"
            fill="#0F0C27"
            data-v-114330cc=""></path>
        </svg>
      </a>
      <p class="footer__copyright-text">© 2011 – 2023 Paygate</p>
      <p class="footer__license-text">
        Регистрационный номер в реестре платежных организаций №02-21-101
      </p>
      <div class="footer__license">
        <div class="footer__license-links">
          <a class="footer__license-link" target="_blank" href="http://paygate.kz/rules">Правила</a>
          <a class="footer__license-link" target="_blank" href="http://paygate.kz/pdf/public.pdf"
            >Публичная оферта об использовании платежного сервиса</a
          >
        </div>
      </div>
    </div>
    <ul class="footer__info-list">
      <li class="footer__info-list-item">
        <div class="info-list__icon">
          <svg
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-v-114330cc="">
            <path
              d="M1.52588e-05 7C1.52588e-05 3.13401 3.13402 0 7.00002 0C10.866 0 14 3.13401 14 7V12C14 13.1046 13.1046 14 12 14H10C9.44773 14 9.00002 13.5523 9.00002 13V9C9.00002 8.44772 9.44773 8 10 8H12.5V7C12.5 3.96243 10.0376 1.5 7.00002 1.5C3.96245 1.5 1.50002 3.96243 1.50002 7V8H4.00002C4.5523 8 5.00002 8.44772 5.00002 9V13C5.00002 13.5523 4.5523 14 4.00002 14H2.00002C1.82736 14 1.65981 13.9781 1.5 13.937L1.50002 14.25C1.50002 15.4409 2.42518 16.4156 3.59597 16.4948L3.75002 16.5L4.87777 16.5008C5.18644 15.6265 6.02007 15 7.00002 15C8.24266 15 9.25002 16.0074 9.25002 17.25C9.25002 18.4926 8.24266 19.5 7.00002 19.5C6.02045 19.5 5.18708 18.874 4.87813 18.0003L3.75002 18C1.74576 18 0.108742 16.4276 0.00521326 14.4492L1.52588e-05 14.25V7Z"
              fill="#1E174F"
              data-v-114330cc=""></path>
          </svg>
        </div>
        <div class="info-list__content">
          <p class="info-list__content-text">Call-центр</p>
          <a href="tel:+77273527032" class="info-list__content-link">+7 727 352 70 32</a>
        </div>
      </li>
      <li class="footer__info-list-item">
        <div class="info-list__icon">
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-v-114330cc="">
            <path
              d="M15.157 14.8823L13.97 16.0562C13.0951 16.9149 11.96 18.0188 10.5642 19.3684C9.69192 20.2118 8.3081 20.2117 7.43598 19.3682L3.94489 15.9723C3.50612 15.5415 3.13886 15.1782 2.84302 14.8823C-0.557393 11.4819 -0.557393 5.96878 2.84302 2.56837C6.24343 -0.832036 11.7566 -0.832036 15.157 2.56837C18.5574 5.96878 18.5574 11.4819 15.157 14.8823ZM11.5002 9.00006C11.5002 7.61924 10.3808 6.49986 9 6.49986C7.61918 6.49986 6.49981 7.61924 6.49981 9.00006C6.49981 10.3809 7.61918 11.5003 9 11.5003C10.3808 11.5003 11.5002 10.3809 11.5002 9.00006Z"
              fill="#1E174F"
              data-v-114330cc=""></path>
          </svg>
        </div>
        <div class="info-list__content">
          <p class="info-list__content-text">г.Алматы</p>
          <a
            target="_blank"
            href="https://yandex.kz/maps/-/CCU14Ww-TD"
            class="info-list__content-link"
            >пр.Сейфуллина, 498, 8 этаж</a
          >
        </div>
      </li>
      <li class="footer__info-list-item">
        <div class="info-list__icon">
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-v-114330cc="">
            <path
              d="M11 -0.000976562C16.5237 -0.000976562 21.0015 4.47687 21.0015 10.0006C21.0015 15.5243 16.5237 20.0021 11 20.0021C5.47629 20.0021 0.998444 15.5243 0.998444 10.0006C0.998444 4.47687 5.47629 -0.000976562 11 -0.000976562ZM10.9962 8.24961C10.4834 8.24994 10.0609 8.63626 10.0035 9.13363L9.99684 9.25026L10.0004 14.7519L10.0072 14.8685C10.0653 15.3658 10.4883 15.7515 11.0011 15.7512C11.5139 15.7509 11.9364 15.3645 11.9938 14.8672L12.0004 14.7505L11.9968 9.24895L11.99 9.13233C11.9319 8.63503 11.509 8.24927 10.9962 8.24961ZM11.0004 4.50006C10.3092 4.50006 9.74892 5.06038 9.74892 5.75158C9.74892 6.44277 10.3092 7.0031 11.0004 7.0031C11.6916 7.0031 12.252 6.44277 12.252 5.75158C12.252 5.06038 11.6916 4.50006 11.0004 4.50006Z"
              fill="#1E174F"
              data-v-114330cc=""></path>
          </svg>
        </div>
        <div class="info-list__content">
          <p class="info-list__content-text">Общие вопросы</p>
          <a href="mailto:info@paygate.kz" class="info-list__content-link">info@paygate.kz</a>
        </div>
      </li>
      <li class="footer__info-list-item">
        <div class="info-list__icon">
          <svg
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-v-114330cc="">
            <path
              d="M7.5 0C3.35786 0 0 3.35786 0 7.5C0 8.63348 0.251933 9.70995 0.703363 10.6749C0.401236 11.9 0.139541 13.2085 0.0217382 13.8173C-0.111564 14.5063 0.487343 15.1099 1.17494 14.9875C1.79825 14.8765 3.15315 14.6267 4.41143 14.3363C5.35412 14.7628 6.40027 15 7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0ZM7.46191 15.9996C8.80012 17.2411 10.5922 18.0002 12.5616 18.0002C13.6611 18.0002 14.707 17.7631 15.6495 17.3368C16.6933 17.5811 17.8358 17.8249 18.563 17.9769C19.4554 18.1634 20.2348 17.3624 20.0304 16.4775C19.8679 15.7741 19.6117 14.6822 19.3586 13.6742C19.8098 12.7095 20.0616 11.6333 20.0616 10.5002C20.0616 7.21724 17.9523 4.42696 15.015 3.41064C15.3653 4.04768 15.6363 4.73448 15.8146 5.45773C17.4676 6.52629 18.5616 8.38547 18.5616 10.5002C18.5616 11.4916 18.3217 12.4248 17.8975 13.247L17.767 13.5L17.8372 13.7758C18.065 14.6713 18.3037 15.6758 18.4787 16.4264C17.7046 16.2627 16.6611 16.0362 15.7395 15.8168L15.475 15.7539L15.2316 15.875C14.4283 16.2749 13.5223 16.5002 12.5616 16.5002C11.5021 16.5002 10.5067 16.2255 9.64272 15.7436C8.97677 15.9112 8.2796 16.0002 7.56163 16.0002C7.52835 16.0002 7.49511 16 7.46191 15.9996Z"
              fill="#1E174F"
              data-v-114330cc=""></path>
          </svg>
        </div>
        <div class="info-list__content">
          <p class="info-list__content-text">Техническая поддержка</p>
          <a href="mailto:support@paygate.kz" class="info-list__content-link">support@paygate.kz</a>
        </div>
      </li>
    </ul>
  </footer>
</template>

<style scoped>
.footer {
  display: flex;

  align-items: flex-start;
  gap: 30px;
  background-color: #242630;
  padding: 50px 20px;
  margin-top: auto;
  transition: background-color 0.3s linear;
}

.sun footer {
  background-color: #f8f8fb;
}

.footer__upper-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.footer__logo {
  max-width: 130px;
  width: 100%;
  height: 25px;
  margin-bottom: 5px;
}

.footer__logo path {
  fill: #fff;
  transition: 0.3s linear;
}

.sun .footer__logo path {
  fill: #0f0c27;
}

.footer__logo:hover path {
  fill: #79d29e;
}

.footer__copyright {
}

.footer__copyright-text {
  font-size: 12px;
  font-weight: 600;
}

.footer__info-list {
  max-width: 1000px;
  width: 100%;
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer__info-list-item {
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-list__icon {
  max-width: 40px;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ececf0;
}

.info-list__content-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}

.info-list__content-link,
.footer__license-link {
  color: #ececf0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 3px;
  transition: color 0.3s linear;
}

.sun .info-list__content-link,
.footer__license-link {
  color: #6c6a7c;
}

.info-list__content-link:hover,
.footer__license-link:hover {
  color: #79d29e;
}

.footer__license {
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding-top: 20px;
}

.footer__license-text {
  font-size: 12px;
  font-weight: 600;
}

.footer__license-links {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-items: flex-end;
}

@media screen and (max-width: 950px) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 30px;
  }

  .footer__copyright {
    width: 100%;
    text-align: center;
  }

  .footer__license {
    align-items: center;
  }

  .footer__license-links {
    align-items: center;
  }

  .footer__logo {
    max-width: 150px;
    height: 30px;
  }

  .footer__info-list {
    max-width: 600px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .info-list__content-text {
    font-size: 12px;
  }

  .footer__info-list-item {
    max-width: 200px;
  }

  .info-list__content-link {
    font-size: 10px;
  }
  .info-list__icon {
    max-width: 30px;
    min-height: 30px;
  }

  .info-list__icon svg {
    width: 15px;
    height: 15px;
  }
}
</style>
